import locale777d96b6 from '../../lang/it.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  0: "@",
  1: "n",
  2: "u",
  3: "x",
  4: "t",
  5: "j",
  6: "s",
  7: "/",
  8: "p",
  9: "w",
  10: "a",
  vueI18n: {"fallbackLocale":"it","silentTranslationWarn":true,"numberFormats":{"en":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"it":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"it","file":"it.js","iso":"it_IT","defaultCurrency":"EUR","countries":["IT","SM","VA"]}],
  defaultLocale: "it",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/vsf/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "US",
  autoChangeCookie: {"currency":false,"locale":false,"country":false},
  normalizedLocales: [{"code":"it","file":"it.js","iso":"it_IT","defaultCurrency":"EUR","countries":["IT","SM","VA"]}],
  localeCodes: ["it"],
}

export const localeMessages = {
  'it.js': () => Promise.resolve(locale777d96b6),
}
